import {
  CenterModel,
  CompositeKeyModel,
  CompositeSubKeyModel,
  ErrorModel,
  ReferAFriendDataModel,
  UserModel,
} from "..";
import { PaymentProvidersEnum } from "./config.model";

export interface PaymentModel {
  id: string;
  requestType: string;
  requestedAmount: string;
  state?: string;
  stateLabel?: string;
  deductionDate: string;
  subId?: number;
  centerId?: number;
}

export interface DirectDebitPaymentModel {
  provider: PaymentProvidersEnum;
  [field: string]: string;
}

export interface DocumentModel {
  type: string;
  data: string;
  name?: string;
}

export interface PayDebtCheckModel {
  userId: number;
  userCenterId: number;
  currency?: string;
}

export interface PaymentLinkModel {
  paymentUrl: {
    url: string;
    postParameters?: { [key: string]: string };
  };
  paymentInfosCacheId: string;
  // All data in paymentProviderExtraData is sent to the frontend and should NOT be private information
  paymentProviderExtraData?: { [key: string]: string };
}

export interface PaymentInfoCacheModel {
  // Generic mandatory stuff
  userId: number;
  centerId: number;
  paymentCenterId: number; // the center where we fetch the payment provider config and make the payment
  payingFor: PaymentForModel;
  retryUrl: string;
  status?: "PAYMENT_IN_PROGRESS" | "PAYMENT_OK" | "PAYMENT_ERROR";
  payingForFamilyMember?: boolean;
  amountToBePaid: number;
  currency?: string;
  debtType?: AccountReceivableType;
  referAFriend?: ReferAFriendDataModel;

  // Payment type specific stuff
  shoppingBasketId?: string;
  paymentAgreement?: CompositeSubKeyModel;
  subscription?: CompositeKeyModel;
  subscriptionStartDate?: string;
  payDebt?: PaymentKeyPayDebtModel;
  resourceBookingInfo?: PaymentKeyResourceBookingModel;
  classBookingInfo?: PaymentKeyClassBookingModel;
  staffBookingInfo?: PaymentKeyStaffBookingModel;
  topUpAccountInfo?: PaymentKeyTopUpAccountModel;
  freezeSubscriptionInfo?: PaymentKeyFreezeSubscriptionModel;

  // Payment provider specific stuff
  paymentProviderExtraData?: { [key: string]: string };

  // RESULT STUFF
  paymentResultOK?: PaymentResultModel;
  paymentResultError?: ErrorModel;
}

export type PaymentSBStatusModel = "IP" | "RD" | "PU" | "PE" | "RE"; // | "CRR" | "CRNR";
export const PaymentSBStatusModel = {
  InProgress: "IP" as PaymentSBStatusModel, // if the cache is in progress only
  Registered: "RD" as PaymentSBStatusModel, // if the shopping basket is registered, or the payment is received by exerp when there is no shopping basket
  PaymentUnsuccessfull: "PU" as PaymentSBStatusModel,
  PaymentError: "PE" as PaymentSBStatusModel,
  RegistrationError: "RE" as PaymentSBStatusModel,
};

export interface PaymentResultModel {
  sbStatus?: PaymentSBStatusModel;
  providerInfos?: EasyPayCaptureStatusModel | PPSRefundStatusModel; // specific to the payment provider, whether it's a capture or a refund
  infos?: string; // legacy, not used anymore (contains the payment agreement id)
  noteInfos?: JournalNotesStatusEnum;
  title?: string;
  redirectUrl?: string;
  retryUrl?: string;

  userId: number;
  userCenterId: number;
  isFromStaff?: boolean;
  amountPaid: number | undefined;
  currency?: string;

  successRegisterActionResponse?: any;
}

export type PaymentForModel =
  | "Clipcard"
  | "Shopping Basket"
  | "Update Payment Agreement"
  | "Create Payment Agreement"
  | "Payment Debt"
  | "Top Up Account"
  | "Course"
  | "Camps"
  | "Resource Booking"
  | "Class Booking"
  // Subscription stuff
  | "Change Subscription"
  | "Change Subscription With New CC Agreement"
  | "Change Subscription With New DD Agreement"
  | "Change Subscription With New Invoice Agreement"
  | "New Subscription EFT DD"
  | "New Subscription EFT DD PL"
  | "New Subscription EFT INVOICE"
  | "New Subscription EFT CC"
  | "New Subscription CASH"
  | "Add Subscription"
  | "Freeze Subscription"
  | "Buy Addons For Subscription";

export const PaymentForModel = {
  Clipcard: "Clipcard" as PaymentForModel,
  ShoppingBasket: "Shopping Basket" as PaymentForModel,
  UpdatePaymentAgreement: "Update Payment Agreement" as PaymentForModel,
  UpdatePaymentAgreementDDPL:
    "Update Payment Agreement DD Payment Link" as PaymentForModel,
  CreatePaymentAgreement: "Create Payment Agreement" as PaymentForModel,
  CreatePaymentAgreementDDPL:
    "Create Payment Agreement DD Payment Link" as PaymentForModel,
  PaymentDebt: "Payment Debt" as PaymentForModel,
  TopUpAccount: "Top Up Account" as PaymentForModel,
  Course: "Course" as PaymentForModel,
  Camps: "Camps" as PaymentForModel,
  ResourceBooking: "Resource Booking" as PaymentForModel,
  ClassBooking: "Class Booking" as PaymentForModel,
  StaffBooking: "Staff Booking" as PaymentForModel,
  // Subscription stuff
  ChangeSubscription: "Change Subscription" as PaymentForModel,
  ChangeSubscriptionWithNewCCAgreement:
    "Change Subscription With New CC Agreement" as PaymentForModel,
  ChangeSubscriptionWithNewDDAgreement:
    "Change Subscription With New DD Agreement" as PaymentForModel,
  ChangeSubscriptionWithNewInvoiceAgreement:
    "Change Subscription With New Invoice Agreement" as PaymentForModel,
  NewSubscriptionEFTDD: "New Subscription EFT DD" as PaymentForModel,
  NewSubscriptionEFTDDPL: "New Subscription EFT DD PL" as PaymentForModel, // PL = Payment Link
  NewSubscriptionEFTINVOICE: "New Subscription EFT INVOICE" as PaymentForModel,
  NewSubscriptionEFTCC: "New Subscription EFT CC" as PaymentForModel,
  NewSubscriptionCASH: "New Subscription CASH" as PaymentForModel,
  AddSubscription: "Add Subscription" as PaymentForModel,
  FreezeSubscription: "Freeze Subscription" as PaymentForModel,
  BuyAddonsForSubscription: "Buy Addons For Subscription" as PaymentForModel,
};

export const LIST_UPDATE_PA = [
  PaymentForModel.UpdatePaymentAgreement,
  PaymentForModel.UpdatePaymentAgreementDDPL,
  PaymentForModel.CreatePaymentAgreement,
  PaymentForModel.CreatePaymentAgreementDDPL,
  PaymentForModel.NewSubscriptionEFTCC,
  PaymentForModel.ChangeSubscriptionWithNewCCAgreement,
  PaymentForModel.NewSubscriptionEFTDDPL,
];

export interface PaymentKeyPayDebtModel {
  consolidatedInvoiceKeys?: CompositeSubKeyModel[];
  payDebtType: "WI" | "WOI";
  sessionKey?: string;
  staffEmpNumber?: string;
}

export interface PaymentKeyClassBookingModel {
  bookingId: number;
  bookingCenterId: number;
  selectedUserId: number;
  selectedUserCenterId: number;
}
export interface PaymentKeyStaffBookingModel {
  bookingId: number;
  bookingCenterId: number;
  selectedUserId: number;
  selectedUserCenterId: number;
}
export interface PaymentKeyTopUpAccountModel {
  topUpAmount: number;
}

export interface PaymentKeyResourceBookingModel {
  activityCenterId: number;
  activityId: number;
  resourceKey: number;
  startTime: string;
  date: string;
  duration: number;
  selectedUserId: number;
  selectedUserCenterId: number;
}

export interface PaymentKeyFreezeSubscriptionModel {
  subscriptionId: number;
  subscriptionCenterId: number;
  fromDate: string;
  toDate: string;
}

export type BankAccountTypeModel = "Checking" | "Savings";

export interface AccountOverviewModel {
  totalPayableDebt?: number;
  currencyCode?: string;
  countryCode?: string;
  cashAccountBalance?: number;
  consolidatedInvoices: Array<AccountOverviewConsolidatedInvoiceModel>;
  payableDebtStatus: AccountOverviewPayableDebtStatus;
  debtPaymentAccounts: {
    accountReceivableType: AccountReceivableType | undefined;
    payableDebt: string | undefined;
  }[];
}

export type AccountReceivableType =
  | "CASH"
  | "PAYMENT"
  | "CASHCOLLECTION"
  | "INSTALLMENTPLAN";
export const AccountReceivableType = {
  Cash: "CASH" as AccountReceivableType,
  Payment: "PAYMENT" as AccountReceivableType,
  Cashcollection: "CASHCOLLECTION" as AccountReceivableType,
  Installmentplan: "INSTALLMENTPLAN" as AccountReceivableType,
};

// new enum
export enum AccountOverviewPayableDebtStatus {
  ACCURATE = "ACCURATE",
  TOTAL_AMOUNT_NOT_MATCHING = "TOTAL_AMOUNT_NOT_MATCHING",
  SOME_AMOUNT_NOT_MATCHING = "SOME_AMOUNT_NOT_MATCHING",
  PARTIAL = "PARTIAL",
  UNKOWN = "UNKOWN",
}

export interface AccountOverviewConsolidatedInvoiceModel {
  id: number;
  center: number;
  subId: number;
  openAmount: number;
  isPayable?: boolean;
  originalAmount?: number;
  alreadyPaidAmount?: number;
  invoiceDate?: string;
  dueDate: string;
  reference?: string;
  selected?: boolean; // UI Only
}

export type PPSRefundStatusModel = "1CRF" | "1CRI" | "1CRA" | "1CRN";
export const PPSRefundStatusModel = {
  OneCentRefundFailed: "1CRF" as PPSRefundStatusModel,
  OneCentRefundIssued: "1CRI" as PPSRefundStatusModel,
  OneCentRefundAlready: "1CRA" as PPSRefundStatusModel,
  OneCentRefundNA: "1CRN" as PPSRefundStatusModel,
};

export type EasyPayCaptureStatusModel = "AC" | "CAP" | "NC";
export const EasyPayCaptureStatusModel = {
  AlreadyCaptured: "AC" as EasyPayCaptureStatusModel,
  Captured: "CAP" as EasyPayCaptureStatusModel,
  NoCapture: "NC" as EasyPayCaptureStatusModel,
};

export type JournalNotesStatusEnum =
  | "NAD / Refund Issued"
  | "NNA / Refund Issued"
  | "NAD/CNR"
  | "NNA/CNR"
  | "NAD/NR"
  | "NNA/NR";
export const JournalNotesStatusEnum = {
  NoteAdded_ClientRefunded: "NAD / Refund Issued" as JournalNotesStatusEnum,
  NoteNotAdded_ClientRefunded: "NNA / Refund Issued" as JournalNotesStatusEnum,
  NoteAdded_ClientNotRefunded: "NAD/CNR" as JournalNotesStatusEnum,
  NoteNotAdded_ClientNotRefunded: "NNA/CNR" as JournalNotesStatusEnum,
  NoteAdded_NoRefundNeeded: "NAD/NR" as JournalNotesStatusEnum,
  NoteNotAdded_NoRefundNeeded: "NNA/NR" as JournalNotesStatusEnum,
};

export interface SaleModel {
  id: number;
  cashRegisterTransactions: CashRegisterTransactionModel[];
  center: CenterModel;
  employeeKey?: string;
  employeeId?: number;
  employeeCenterId?: number;
  employeeExternalId?: string;
  employeeName: string;
  firstLineText: string;
  invoiceKey?: CompositeKeyModel;
  creditNoteKey?: CompositeKeyModel;
  lineCount: number;
  payerId?: number;
  payerCenterId?: number;
  payerExternalId?: string;
  totalAmount: number;
  transactionTime: string;
  vatAmount: number;
  currency?: string;
}

export interface CashRegisterTransactionModel {
  amount: number;
  type: string;
}

export interface InvoiceModel {
  totalAmount?: string;
  totalVAT?: string;
  invoiceText?: string;
  employeeKey?: CompositeKeyModel;
  payerId?: number;
  payerCenterId?: number;
  payerExternalId?: string;
  vatInfos?: Array<VATInfoModel>;
  invoiceLines?: Array<InvoiceLineModel>;
  salesTaxes?: Array<SalesTaxModel>;
  paymentMethod?: TransactionTypeEnumModel;
}

export interface VATInfoModel {
  amount?: string;
  rate?: string;
}

export interface InvoiceLineModel {
  productKeyId?: number;
  productKeyCenterId?: number;
  quantity?: number;
  text?: string;
  amount?: string;
  vatAmount?: string;
  vatRate?: string;
  purchaserKeyId?: number;
  purchaserKeyCenterId?: number;
  purchaserKeyExternalId?: string;
  salesTaxes?: Array<SalesTaxModel>;
  externalId?: string;
}

export interface SalesTaxModel {
  name?: string;
  amount?: string;
  rate?: string;
}

export type TransactionTypeEnumModel =
  | "CASH"
  | "CHANGE"
  | "RETURN"
  | "PAYOUT_CASH"
  | "CASH_AR"
  | "DEBIT_CARD"
  | "CREDIT_CARD"
  | "CARD_UNKNOWN"
  | "GIFT_CARD"
  | "CASH_ADJUST"
  | "TRANSFER_BANK_CASH_NOTES"
  | "PAYMENT_AR"
  | "CUSTOM_PAYMENT_METHOD"
  | "CASH_REGISTER_PAYOUT"
  | "CREDIT_CARD_ADJUST"
  | "CLOSING_CASH_ADJUST"
  | "VOUCHER"
  | "PAYOUT_CREDIT_CARD"
  | "TRANSFER_CASH_REGISTER_CASH"
  | "CLOSING_CREDIT_CARD_ADJUST"
  | "TRANSFER_BANK_CASH_COINS"
  | "INSTALLMENT_PLAN"
  | "INITIAL_CASH"
  | "MANUAL";
export const TransactionTypeEnum = {
  Cash: "CASH" as TransactionTypeEnumModel,
  Change: "CHANGE" as TransactionTypeEnumModel,
  Return: "RETURN" as TransactionTypeEnumModel,
  PayoutCash: "PAYOUT_CASH" as TransactionTypeEnumModel,
  CashAR: "CASH_AR" as TransactionTypeEnumModel,
  DebitCard: "DEBIT_CARD" as TransactionTypeEnumModel,
  CreditCard: "CREDIT_CARD" as TransactionTypeEnumModel,
  CardUnknown: "CARD_UNKNOWN" as TransactionTypeEnumModel,
  GiftCard: "GIFT_CARD" as TransactionTypeEnumModel,
  CashAdjust: "CASH_ADJUST" as TransactionTypeEnumModel,
  TransferBankCashNotes: "TRANSFER_BANK_CASH_NOTES" as TransactionTypeEnumModel,
  PaymentAR: "PAYMENT_AR" as TransactionTypeEnumModel,
  CustomPaymentMethod: "CUSTOM_PAYMENT_METHOD" as TransactionTypeEnumModel,
  CashRegisterPayout: "CASH_REGISTER_PAYOUT" as TransactionTypeEnumModel,
  CreditCardAdjust: "CREDIT_CARD_ADJUST" as TransactionTypeEnumModel,
  ClosingCashAdjust: "CLOSING_CASH_ADJUST" as TransactionTypeEnumModel,
  Voucher: "VOUCHER" as TransactionTypeEnumModel,
  PayoutCreditCard: "PAYOUT_CREDIT_CARD" as TransactionTypeEnumModel,
  TransferCashRegisterCash:
    "TRANSFER_CASH_REGISTER_CASH" as TransactionTypeEnumModel,
  ClosingCreditCardAdjust:
    "CLOSING_CREDIT_CARD_ADJUST" as TransactionTypeEnumModel,
  TransferBankCashCoins: "TRANSFER_BANK_CASH_COINS" as TransactionTypeEnumModel,
  InstallmentPlan: "INSTALLMENT_PLAN" as TransactionTypeEnumModel,
  InitialCash: "INITIAL_CASH" as TransactionTypeEnumModel,
  Manual: "MANUAL" as TransactionTypeEnumModel,
};

export interface CreditNoteModel {
  creditNoteId?: number;
  creditNoteCenterId?: number;
  invoiceKeyId?: number;
  invoiceKeyCenterId?: number;
  center?: CenterModel;
  totalAmount?: string;
  totalVat?: string;
  transactionTime?: string;
  employeeKeyId?: number;
  employeeKeyCenterId?: number;
  employeeName?: string;
  headerText?: string;
  comment?: string;
  lines?: CreditNoteLineModel[];
  cashRegisterTransactions?: CashRegisterTransactionModel[];
}

export interface CreditNoteLineModel {
  invoiceLineKey?: CompositeSubKeyModel;
  key?: CompositeSubKeyModel;
  personKey?: CompositeKeyModel;
  productExternalId: string;
  productKey?: ProductKey;
  productType: string;
  quantity: number;
  reasonType: string;
  totalAmount: number;
  vatAmount: number;
}

export interface ProductKey {
  center: number;
  id: number;
}

export interface SaleDetailModel {
  invoiceDetails?: InvoiceModel;
  creditNoteDetails?: CreditNoteModel;
}

export interface PaymentMethod {
  accountId?: string;
  accountNumber?: string;
  accountName?: string;
  type?: string;
  purpose?: string;
  expirationDate?: string;
  cOFMemberID?: string;
  cardLabel?: string;
}

export interface PayWayResponse {
  status: "declined" | "approved";
  tranId: string | undefined;
  customerId: string | undefined;
}

import {
  ApiPersonKeyModel,
  AvailableInstructorsModel,
  ModifiedTrainingAvailabilityModel,
  PersonalTrainingActivityModel,
  TimeIntervalModel,
} from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class StaffBookingService {
  constructor() {}

  loadPersonalTrainingAvailibilityForActivity(
    centerId: number,
    activityId: number,
    date: string,
    durations: number[]
  ): Promise<ModifiedTrainingAvailabilityModel[]> {
    return backendApi.post('staff-booking/get-personal-training-availability-for-activity', { centerId, activityId, date, durations });
  }
  loadPersonalTrainingAvailibilityForInstructor(
    centerId: number,
    instructorKey: ApiPersonKeyModel,
    activityId: number,
    date: string,
    durations: number[]
  ): Promise<ModifiedTrainingAvailabilityModel[]> {
    return backendApi.post('staff-booking/get-personal-training-availability-for-instructor', {
      centerId,
      instructorKey,
      activityId,
      date,
      durations,
    });
  }
  loadPersonalTrainingActivitiesForCenter(centerId: number): Promise<PersonalTrainingActivityModel[]> {
    return backendApi.get('staff-booking/get-personal-training-activities-for-centers', { centerId });
  }
  loadPersonalInstructorsForCenter(): Promise<AvailableInstructorsModel> {
    return backendApi.post('staff-booking/get-personal-instructors-for-centers');
  }
  bookInstructor(
    activityId: number,
    centerId: number,
    activityCenterId: number | undefined,
    key: number,
    startTime: string | undefined,
    date: string,
    duration: number
  ): Promise<boolean> {
    return backendApi.post('staff-booking/book-instructor', {
      activityId,
      centerId,
      key,
      activityCenterId,
      startTime,
      date,
      duration,
    });
  }
}

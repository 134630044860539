import { ActivityModel, ActivityType, BookingDatesModel, BookingSearchModel, ParticipationModel, SeatModel } from '../../../common/src';
import * as backendApi from '../shared/backend-api';

export default class ClassesService {
  constructor() {}

  findBookingsDates(
    selectedUserId: number | undefined,
    selectedUserCenterId: number | undefined,
    startDate: string,
    endDate: string,
    isStaffBooking: boolean | undefined
  ): Promise<BookingDatesModel[]> {
    return backendApi.post('classes/find-booking-dates', { selectedUserId, selectedUserCenterId, isStaffBooking, startDate, endDate });
  }

  searchBookingParticipations(
    selectedUserId: number | undefined,
    selectedUserCenterId: number | undefined,
    searchFilter: BookingSearchModel
  ): Promise<ParticipationModel[]> {
    return backendApi.post('classes/search-booking-participations', searchFilter, { selectedUserId, selectedUserCenterId });
  }

  loadInstructors(selectedDate: string, centersIds: number[]): Promise<string[]> {
    return backendApi.post('classes/get-instructors-from-activities', {
      centersIds,
      date: selectedDate,
    });
  }

  loadAvailableActivitiesForCenter(centersIds: number[], activityTypes: ActivityType[]): Promise<ActivityModel[]> {
    return backendApi.get('classes/available-activities-for-centers', { centersIds, activityTypes });
  }
}

import { CompositeKeyModel } from "..";
import { ExtendedAttributeModel } from "./company.model";
import { ConfigWebUserModel } from "./config.model";
import { CenterModel } from "./params.model";
import { AddressModel, PersonType } from "./user.model";

export interface PersonDetailModel {
  gender: ApiGenderModel;
  id?: number;
  centerId?: number;
  salutation?: string;
  firstName?: string;
  lastName?: string;
  birthday?: string;
  ssn?: string;
  address1?: string;
  address2?: string;
  zip?: string;
  city?: string;
  state?: string;
  country?: string;
  passportNumber?: string;
  passportExpiration?: string;
  passportIssuingCountry?: string;
  residentId?: string;
  nationalId?: string;
  homeCenter?: string;

  /** communication details */
  email?: string;
  mobile?: string;
  preferredLanguage?: string;
  allowEmailNewsletters?: boolean;
  allowThirdPartyOffers?: boolean;
  allowLetter?: boolean;
  allowEmail?: boolean;
  allowSMS?: boolean;
  allowChargedSMS?: boolean;
  allowPhone?: boolean;
}

export interface LocaleModel {
  salutations: SalutationModel[];
  languages: LanguageModel[];
}

export interface CountryModel {
  code: string;
  name: string;
}
export interface StateModel {
  code: string;
  name: string;
}
export interface LanguageModel {
  value: string;
  name: string;
}

export interface SalutationModel {
  value: string;
  name: string;
  gender: string;
}

export interface FamilyModel {
  familyId: number;
  familyName?: string;
  primaryPerson?: PersonDetailModel;
  familyMembers?: Array<FamilyMemberModel>;
  isPrimary?: boolean;
}

export interface FamilyMemberModel {
  personDetail: PersonDetailModel;
  memberType: FamilyMemberTypeModel;
  allowCardOnFile: boolean;
}

export type FamilyMemberTypeModel = "SPOUSE" | "PARTNER" | "CHILD" | "OTHER";
export const FamilyMemberTypeModel = {
  Spouse: "SPOUSE" as FamilyMemberTypeModel,
  Partner: "PARTNER" as FamilyMemberTypeModel,
  Child: "CHILD" as FamilyMemberTypeModel,
  Other: "OTHER" as FamilyMemberTypeModel,
};

export type ApiGenderModel = "MALE" | "FEMALE" | "UNDEFINED";
export const ApiGenderModel = {
  Male: "MALE" as ApiGenderModel,
  Female: "FEMALE" as ApiGenderModel,
  Undefined: "UNDEFINED" as ApiGenderModel,
};

export interface GenderNameValueModel {
  value: ApiGenderModel;
  name: string;
}

export const GENDERS: GenderNameValueModel[] = [
  { value: ApiGenderModel.Female, name: "Female" },
  { value: ApiGenderModel.Male, name: "Male" },
  { value: ApiGenderModel.Undefined, name: "Undefined" },
];

export const ONLY_DEFINED_GENDERS: GenderNameValueModel[] = [
  { value: ApiGenderModel.Female, name: "Female" },
  { value: ApiGenderModel.Male, name: "Male" },
];

export interface VueTelInputModel {
  countryCallingCode: string;
  nationalNumber: string;
  number: string;
  country: Country;
  countryCode: string;
  valid: boolean;
  formatted: string;
}

export interface Country {
  name: string;
  iso2: string;
  dialCode: string;
}

export type StaffRolesModel =
  | "RaiseClipcardPrice"
  | "ReduceClipcardPrice"
  | "OverrideGlobalRecurringClipSubsDefinition";
export const StaffRolesModel = {
  RaiseClipcardPrice: "RaiseClipcardPrice" as StaffRolesModel,
  ReduceClipcardPrice: "ReduceClipcardPrice" as StaffRolesModel,
  OverrideGlobalRecurringClipSubsDefinition:
    "OverrideGlobalRecurringClipSubsDefinition" as StaffRolesModel,
};

export interface MemberFormConfigModel {
  systemPropertyConfig: ConfigWebUserModel;
  mode: "CHANGE" | "JOIN" | "ADD" | "LEAD" | "PROFILE" | "";
  prefillValues: "NONE" | "ALL" | "DEFAULT";
  viewMode: "EDIT" | "VIEW";
  selectedCenter?: CenterModel;
  personDetail?: PersonDetailModel;
  leadType?: "Web" | "StandAlone";
}

export interface WebLeadSummaryModel {
  selectedCenter: CenterModel;
  configForNewMember: ConfigWebUserModel; // TODO DEV-47286 Dont know about his one...
}

export interface PersonsCreateLeadModel {
  centerId: number;
  nickName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  socialSecurityNumber?: string;
  homePhone?: string;
  mobilePhone?: string;
  email?: string;
  birthday?: string;
  gender?: ApiGenderModel;
  personType?: PersonType;
  comment?: string;
  employeeNumber?: string;
  nationalID?: string;
  residentID?: string;
  // passport?: Passport;
  extendedAttributes?: Array<ExtendedAttributeModel>;
  relatedPersonId?: CompositeKeyModel;
  address?: AddressModel;
}

import { StrapLineConfig } from "./strapLine.model";

export class ConfigLookAndFeelModel {
  constructor(
    public siteLogo?: string,
    public favicon?: string,
    public dashboardBanner?: string,
    public bookingBanner?: string,
    public staffBookingBanner?: string,
    public resourceBookingBanner?: string,
    public courseBanner?: string,
    public campsBanner?: string,
    public profileBanner?: string,
    public paymentsBanner?: string,
    public clipcardBanner?: string,
    public joinFlowBanner?: string,
    public mainColor?: string,
    public secondaryColor?: string,
    public highlightColor?: string,
    public customCSS?: string,
    public footerLinks?: { [key: string]: string },
    public classImages: { [key: string]: string } = {},
    public addonImages: { [key: string]: string } = {},
    public makeAwareColor?: string,
    public standaloneLeadFlowBackgroundImageUrl?: string,
    public bannerSummaryBackgroundColor?: string,
    public bannerSummaryTextColor?: string,
    public bannerSummaryButtonBackgroundColor?: string
  ) {}
}
export class ConfigBrazeModel {
  constructor(public url?: string, public authCode?: string) {}
}
export class ConfigRedirectsModel {
  constructor(
    public changePaymentAgreement?: string,
    public clipcardPurchase?: string,
    public logout?: string,
    public createAccount?: string,
    public login?: string,
    public subscriptionPurchase?: string,
    public payShoppingBasket?: string,
    public homeAnonymous?: string,
    public homeMember?: string,
    public bringAFriendFormCompleted?: string,
    public referAFriendFormCompleted?: string,
    public outgoingRedirects?: { [key: string]: string },
    public passwordReset?: string,
    public cancelLeadFlow?: string,
    public addonOnProfile?: string,
    public bookNewOnDashboard?: string
  ) {}
}

export class ConfigFeaturesModel {
  constructor(
    public changePaymentAgreement: boolean = false,
    public accountTopUp: boolean = false,
    public payDebt: boolean = false,
    public displayExternalDebtAccount: boolean = false,
    public payExternalDebtAccount: boolean = false,
    public payShoppingBasket: boolean = false,
    public hideTrainer: boolean = false,
    public hideInvoice: boolean = false,
    public freezeSubscription: boolean = false,
    public terminateSubscription: boolean = false,
    // public upgradeSubscription: boolean = false, // distinction between 'upgrade' and 'downgrade' will be made later
    // public downgradeSubscription: boolean = false, // for now it's changeSubscription = downgradeSubscription OR upgradeSubscription
    public changeSubscription: boolean = false, // will go away when we make distinction between 'downgrade' and 'upgrade'
    public purchaseNewSubscription: boolean = false, // this is for adding another subscription to someone who already has one (not LEAD and not INACTIVE)
    public enableJoinFlow: boolean = false, // this is for the join flow
    public enableDeepLinkOnJoinFlow: boolean = false, // this is for the join flow
    public enableFamilyRelations: boolean = false, // this is for the join flow
    public googleMapShowOnClub: boolean = false,
    public retentionCampaign: boolean = false,
    public includeQuestionnaireInJoinFlow?: string, // possible values : beforeJoin,afterJoin,excluded
    public includeQuestionnaireInSellSubscriptionForExistingMember?: string, // possible values : beforeJoin,afterJoin,excluded
    public includeQuestionnaireInSellSubscriptionToChangeMembership?: string, // possible values : beforeJoin,afterJoin,excluded
    public includeQuestionnaireInCamps: boolean = false,
    public promoCodeToSellAnotherSubscription: boolean = false,
    public promoCodeToChangeSubscription: boolean = false,
    public promoCodeOnJoinFlow: boolean = false,
    public cardOnFile: boolean = true,
    public priceDefinitionsIncludeSalesTax: boolean = false, // todo: put this in the right place
    public minimumageforcreatingnewperson: number = 0,
    public agebelowrequiresguardian: number = 0,
    public allowSubscriptionSaleOutsideHomeCenter: boolean = false,
    public courseShowSemesters: boolean = true,
    public campsShowSemesters: boolean = false,
    public buyClipcardForBooking: boolean = false,
    public buyNewSubscriptionForBooking: boolean = false,
    public changeSubscriptionForBooking: boolean = false,
    public allowMultiplePaymentAgreements: boolean = false,
    public enableCreateLead: boolean = false,
    public enableSellingSubscriptionsForRelations: boolean = false,
    public EnableSellingClipcardsForRelations: boolean = false,
    public regretTermination: boolean = false,
    public enableStaffToBuyClipcardSubscriptions: boolean = false,
    public enableInstallmentsForCashSubscriptions: boolean = false,
    public userIdRequired: boolean = false,
    public enableSalesList: boolean = false,
    public numberOfLevelsAboveClub: number = 0,
    public freezestarttofollowbillingcycle: boolean = false,
    public freezestarttofollowbillingcyclenumberofbillingcycles: number = 0,
    public freezeendtofollowbillingcyclenumberofbillingperiods: number = 0,
    public allowStopOngoingFreeze: boolean = true,
    public includeAttendsInDashboard: boolean = false,
    public preferredMethodInJoinflow?: String,
    public childMinimumAge?: String,
    public childMaximumAge: number = 0,
    public enableLeadPage: boolean = false,
    public enableStandbyListForCourses: boolean = false,
    public buyAddonsOnProfile: boolean = false,
    public disableDownloadOfSalesList: boolean = false,
    public stopAddons: boolean = false,
    public specificAddonsToStop?: String,
    public documentsOnProfile: boolean = false,
    public unsignedDocuments: boolean = false
  ) {}
}

export class ConfigGeneralModel {
  constructor(
    public loginLockoutPeriod: number,
    public maximumLoginAttempts: number,
    public sessionTimeout: number,
    public googleMapAPIKey?: string,
    public googleTagManagerClientId?: string,
    public googleAnalyticsClientId?: string,
    public googleAnalyticsExerpId?: string,
    public apiPaymentAccount?: string,
    public managerRole?: string[]
  ) {}
}

export class ConfigMenuModel {
  constructor(
    public clipcardFlow: number = 0,
    public classBookingFlow: number = 0,
    public staffBookingPage: number = 0,
    public classBookingFlowAnonymous: boolean = false,
    public paymentPage: number = 0,
    public memberSearchPage: number = 0,
    public coursesPage: number = 0,
    public campsPage: number = 0,
    public resourceBookingPage: number = 0,
    public shoppingBasketPage: number = 0,
    public dashboardPage: number = -1, // default first page
    public profilePage: boolean = true,
    public loginPage: boolean = true,
    public bringAFriendPage: boolean = false,
    public referAFriendPage: boolean = false
  ) {}
}

export type VIEW_NOT_USED_REQUIRED = "view" | "not-used" | "required";
export type VIEW_NOT_USED_OPTIONAL_REQUIRED =
  | "view"
  | "not-used"
  | "optional"
  | "required";
export type NOT_USED_REQUIRED = "not-used" | "required";
export type NOT_USED_OPTIONAL_REQUIRED = "not-used" | "optional" | "required";
export type USE_ACTIVITY_OR_BOOKING_NAME = "activity" | "booking";

export interface ConfigWebUserModel {
  salutation?: VIEW_NOT_USED_REQUIRED;
  salutationDefaultValue?: string;
  gender?: VIEW_NOT_USED_REQUIRED; // Specific to the profile
  genderDefaultValue?: string;
  hideUndefinedGender: boolean; // default false,
  country?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  countryDefaultValue?: string;
  mobile?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  address1?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  address2?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  zip?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  zipDefaultValue?: string;
  city?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  cityDefaultValue?: string;
  state?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  stateDefaultValue?: string;
  birthday?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  ssn?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  passport?: VIEW_NOT_USED_REQUIRED;
  residentId?: VIEW_NOT_USED_REQUIRED;
  nationalId?: VIEW_NOT_USED_REQUIRED;
  preferredLanguage?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  allowEmailNewsletters: boolean; // default false
  allowThirdPartyOffers: boolean; // default false
  termsAndConditions?: string;
  checkbox1?: string;
  checkbox2?: string;
  checkbox3?: string;
  checkboxWhenClipcardIsSelected?: string;
  htmlContract?: string;
  disableEmailValidation: boolean;
  minimumAge?: number;
  subscriptionStartDate: boolean; // default = false, specific to the join flow
  // profile specific fields
  email?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  password?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  billing?: VIEW_NOT_USED_OPTIONAL_REQUIRED;
  firstName?: VIEW_NOT_USED_REQUIRED;
  lastName?: VIEW_NOT_USED_REQUIRED;
  excludedProductsFromBuyNewFlow?: string;
  excludedProductsFromChangeFlow?: string;
  clipcardProductGroupsInJoinFlow?: string;
  enableClipcardInJoinFlowForMemberships?: string;
  extendedAttributeId?: string;
  deductionDateEqualStartDate?: boolean;
  preselectedProductGroups?: string;
  preselectMostPopular?: boolean;
  enableBannerSummary?: boolean;
  enableSideBySideDisplayOfMemberships?: boolean;
  productsGroupThatIncludePeriodCalculation?: string;
  displayHomeClub?: boolean;
}

export type PaymentProvidersEnum =
  | "pps"
  | "easypay"
  | "adyen"
  | "pcipal"
  | "tamara"
  | "tabby"
  | "spotii"
  | "paytabs"
  | "ltps"
  | "postfinance"
  | "payway"
  | "ezypay"
  | "paymentddagreementwithoutpaymentprovider";
export const PaymentProvidersEnum = {
  PPS: "pps" as PaymentProvidersEnum,
  EasyPay: "easypay" as PaymentProvidersEnum,
  PciPal: "pcipal" as PaymentProvidersEnum,
  Adyen: "adyen" as PaymentProvidersEnum,
  Tamara: "tamara" as PaymentProvidersEnum,
  Tabby: "tabby" as PaymentProvidersEnum,
  Spotii: "spotii" as PaymentProvidersEnum,
  PayTabs: "paytabs" as PaymentProvidersEnum,
  LTPS: "ltps" as PaymentProvidersEnum,
  PostFinance: "postfinance" as PaymentProvidersEnum,
  PayWay: "payway" as PaymentProvidersEnum,
  HyperPay: "hyperpay" as PaymentProvidersEnum,
  EzyPay: "ezypay" as PaymentProvidersEnum,
  ExerpDD: "paymentddagreementwithoutpaymentprovider" as PaymentProvidersEnum,
};

export class ConfigAcceptedDDPaymentProvidersModel {
  constructor(
    // public joinFlow: PaymentProvidersEnum[] = [],
    public updateDDPA: PaymentProvidersEnum[] = [], // used for changing direct debit payment agreement
    public createDDPA: PaymentProvidersEnum[] = [], // used for creating direct debit payment agreement
    public createInvoicePA: PaymentProvidersEnum[] = [], // used for creating invoice payment agreement
    public updateInvoicePA: PaymentProvidersEnum[] = [], // used for updating invoice payment agreement
    public createDDPLPA: PaymentProvidersEnum[] = [], // used for creating direct debit payment agreement with payment link
    public updateDDPLPA: PaymentProvidersEnum[] = [] // used for updating direct debit payment agreement with payment link
  ) {}
}

export class ConfigAcceptedCCPaymentProvidersModel {
  constructor(
    public joiningFees: PaymentProvidersEnum[] = [],
    public changingFees: PaymentProvidersEnum[] = [],
    public clipcardFlow: PaymentProvidersEnum[] = [],
    public freezeSubscription: PaymentProvidersEnum[] = [],
    // public changeSubscription: PaymentProvidersEnum[] = [],  unused. We use JoingFlow instead
    // public purchaseSubscription: PaymentProvidersEnum[] = [],  unused. We use JoingFlow instead
    public payDebt: PaymentProvidersEnum[] = [],
    public accountTopUp: PaymentProvidersEnum[] = [],
    public payShoppingBasket: PaymentProvidersEnum[] = [],
    public updateCCPA: PaymentProvidersEnum[] = [],
    public createCCPA: PaymentProvidersEnum[] = [],
    public courses: PaymentProvidersEnum[] = [],
    public camps: PaymentProvidersEnum[] = [],
    public resourceBooking: PaymentProvidersEnum[] = [],
    public classBooking: PaymentProvidersEnum[] = [],
    public staffBooking: PaymentProvidersEnum[] = []
  ) {}
}

export class ConfigModel {
  constructor(
    public general: ConfigGeneralModel,
    public lookAndFeel: ConfigLookAndFeelModel,
    public features: ConfigFeaturesModel,
    public redirects: ConfigRedirectsModel,
    public profile: ConfigWebUserModel,
    public paymentProvidersCC: ConfigAcceptedCCPaymentProvidersModel,
    public paymentProvidersDD: ConfigAcceptedDDPaymentProvidersModel,
    public paymentProvidersConfig: PaymentProvidersConfigModel,
    public menu: ConfigMenuModel,
    public joinFlow: ConfigWebUserModel,
    public clipcardflow: ConfigClipCardModel,
    public shoppingbasketflow: ConfigShoppingBasketModel,
    public translations: ConfigTranslationModel,
    public ssa: ConfigSSAModel,
    public webLeadFlow: ConfigWebUserModel,
    public standaloneFlow: ConfigWebUserModel,
    public exerpWebLead: ConfigExerpWebLeadModel,
    public primaveraProperties: ConfigPrimaveraModel,
    public paymentProviderGlobalSettings: ConfigPaymentProviderGlobalModel,
    public retention: ConfigRetentionModel,
    public bringafriendflow: ConfigBringAFriendFlowModel,
    public referafriendflow: ConfigReferAFriendFlowModel,
    public contentBlocks: ConfigContentBlocksModel,
    public strapLines: ConfigStrapLineModel,
    public updateGDPRModel: ConfigUpdateGDPRModel,
    public qualitopProperties: ConfigQualitopModel,
    public bookingProperties: ConfigBookingModel,
    public zipCodeList: ConfigZipCodeListModel,
    public staff: ConfigStaffModel,
    public braze: ConfigBrazeModel,
    public sellingPoints: ConfigSellingPointsModel,
    public clientName?: string,
    public configCenterId?: number
  ) {}
}
export interface VersionModel {
  branch?: string;
  commit?: string;
  tag?: string;
  dirty?: string;
  api?: string;
  health?: string;
  buildTime?: string;
}

export class ConfigClipCardModel {
  termsAndConditions?: string;
  showValidDateOnClipcardSales: boolean = true;
  clipcardProductsGroupsRequireActiveMembership?: string;
  clipcardRequireSpecificMembership?: string;
  clipcardProductGroupsIncludedInBookingWithoutPrivilege?: string;
}
export class ConfigShoppingBasketModel {
  termsAndConditions?: string;
}
export class PrimaveraCredentialsModel {
  username?: string;
  passwordKey?: string;
  companyName?: string;
}
export class ConfigTranslationModel {
  [name: string]: TranslationModel;
}

export interface TranslationModel {
  code: string;
  locale: string;
  numberFormat: string;
  dateFormat: string;
  dateFormatFull: string; // es_ES or pt
  longName: string; // example: 'English', 'Danish', 'العربية',...
  shortName: string; // example: 'EN', 'DA', 'ع',...
  direction: "rtl" | "ltr";
  order: number;
  [key: string]: any;
}

export class ConfigSSAModel {
  termsAndConditions?: string;
}

export class ConfigBringAFriendFlowModel {
  termsAndConditions?: string;
  contractCheckbox?: string;
}
export class ConfigReferAFriendFlowModel {
  passwordForGeneratingFriendCode?: string;
  extractionId?: string;
}

export class ConfigContentBlocksModel {
  htmlContents?: { [key: string]: string };
}

export class ConfigZipCodeListItemModel {
  [key: string]: { Country: string; Format: string };
}

export class ConfigZipCodeListModel {
  zipCodeList?: ConfigZipCodeListItemModel;
}

export interface ConfigSellingPointsItemModel {
  [key: string]: string;
}

export class ConfigSellingPointsItemsModel {
  [key: string]: ConfigSellingPointsItemModel[];
}
export class ConfigSellingPointsModel {
  sellingPoints?: ConfigSellingPointsItemsModel;
}

export class ConfigStrapLineModel {
  htmlContents?: { [key: string]: StrapLineConfig };
}

export class ConfigStaffModel {
  setStartupClipsToZeroForClipcardsSubscription?: boolean = false;
  createEFTPaymentAgreementForRecurringClipCardSubscription: boolean = false;
}

export class ConfigUpdateGDPRModel {
  enabled?: boolean;
  htmlText?: string;
  checkbox?: string;
  extendedAttributeName?: string;
  redirectionUrl?: string;
}

export class ConfigQualitopModel {
  enabled?: boolean;
  extractId?: string;
  logo?: string;
}

export class ConfigBookingModel {
  useActivityOrBookingName?: USE_ACTIVITY_OR_BOOKING_NAME;
  bookNew: boolean = true;
  enableStaffBookingSearchByActivity: boolean = false;
  enableStaffBookingSearchByInstructor: boolean = false;
  timeIntervalForStaffBooking?: string;
  staffGroupIds?: string;
}

export interface ConfigExerpWebLeadModel {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nickName?: string;
  email?: string;
  password?: string;
  homePhone?: string;
  mobilePhone?: string;
  ssn?: string;
  birthDate?: string;
  gender?: string;
  comment?: string;
  passport?: string;
  nationalId?: string;
  residentId?: string;
}

export class ConfigPrimaveraModel {
  primaveraEnabled: boolean = false;
}

export class ConfigPaymentProviderGlobalModel {
  customPaymentMethodId?: number;
  ddGuaranteeHtmlText?: string;
  generateDDDocument?: boolean;
  onlyAllowCreateUpdateSamePaymentAgreementType: boolean = false;
}

export class ConfigRetentionModelItem {
  redirectionUrl?: string;
  extendedAttributeName?: string;
  name?: string;
}
export class ConfigRetentionModel {
  retentionOffers?: ConfigRetentionModelItem[];
  enableExtraQuestion?: boolean;
}
export interface PaymentProviderConfigModel {
  paymentProvider: PaymentProvidersEnum;
  allowedForStaff: boolean;
  allowedForMember: boolean;
  logo?: string;
  blockedPaymentMethods?: string[];
  exerpDDmandatoryFields?: string[];
  exerpDDoptionalFields?: string[];
}

export type PaymentProvidersConfigModel = {
  [key in PaymentProvidersEnum]?: PaymentProviderConfigModel;
};

import {
  EasyPayCaptureStatusModel,
  JournalNotesStatusEnum,
  PaymentResultModel,
  PaymentSBStatusModel,
  PPSRefundStatusModel,
} from "./models/payment.model";

export * from "./models/booking.model";
export * from "./models/staff-booking.model";
export * from "./models/documents.model";
export * from "./models/courses.model";
export * from "./models/clipcard.model";
export * from "./models/config.model";
export * from "./models/params.model";
export * from "./models/payment.model";
export * from "./models/person.model";
export * from "./models/product.model";
export * from "./models/subscription.model";
export * from "./models/user.model";
export * from "./models/questionnaire.model";
export * from "./models/shopping-basket.model";
export * from "./models/resource-booking.model";
export * from "./models/bring-a-friend.model";
export * from "./models/refer-a-friend.model";
export * from "./models/company.model";
export * from "./models/shared.model";
export * from "./models/strapLine.model";

export class ErrorModel {
  public serverDateTime?: string;
  public correlationId?: string;

  constructor(
    public errorI18NMessage: string, // The message code of the entry in the i18n file
    public httpCode: number = 500, // The HTTP code to send back to the frontend. Defaults to 500
    public errorCode: ErrorCodeModel = {} // An error code that can be used on the UI. Optional.
  ) {}
}

// TODO: merge some of these fields together
export interface ErrorCodeModel {
  extraDetails?: string[];
  paymentResult?: PaymentResultModel;
  bookingDetails?: string[];
  subscriptionDetails?: ErrorCodeSubscriptionModel;
  errorType?: ErrorTypesEnum;
  duplicateFields?: string[];
  lockoutPeriod?: number;
}

export interface ErrorCodeSubscriptionModel {
  date?: string;
  state?: string;
  others?: string;
}

export enum ErrorTypesEnum {
  BASKET_NOT_FOUND = "BASKET_NOT_FOUND",
  NoPrivilege = "NoPrivilege",
  PURCHASE_EXCEEDED = "PURCHASE_EXCEEDED",
  PURCHASE_CLIPCARD_EXCEEDED = "PURCHASE_CLIPCARD_EXCEEDED",
}

export type TimeUnitModel =
  | "YEAR"
  | "MONTH"
  | "WEEK"
  | "DAY"
  | "HOUR"
  | "MINUTE"
  | "SECOND";

export interface CompositeSubKeyModel {
  center: number;
  id: number;
  subId: number;
}

export interface CompositeKeyModel {
  center: number;
  id: number;
  externalId?: string;
}

import { CompositeKeyModel } from "./../index";
import { WithAgeRestrictionModel } from "./courses.model";

export type ActivityType =
  | "GENERAL"
  | "CLASS"
  | "RESOURCE_AVAILABILITY"
  | "RESOURCE_BOOKING"
  | "STAFF_AVAILABILITY"
  | "STAFF_BOOKING"
  | "CHILD_CARE"
  | "MEETING"
  | "COURSE"
  | "TASK"
  | "CAMP"
  | "CAMP_ELECTIVE"
  | "RESOURCE_MAINTENANCE";

export const ActivityType = {
  GENERAL: "GENERAL" as ActivityType,
  CLASS: "CLASS" as ActivityType,
  RESOURCE_AVAILABILITY: "RESOURCE_AVAILABILITY" as ActivityType,
  RESOURCE_BOOKING: "RESOURCE_BOOKING" as ActivityType,
  STAFF_AVAILABILITY: "STAFF_AVAILABILITY" as ActivityType,
  STAFF_BOOKING: "STAFF_BOOKING" as ActivityType,
  CHILD_CARE: "CHILD_CARE" as ActivityType,
  MEETING: "MEETING" as ActivityType,
  COURSE: "COURSE" as ActivityType,
  TASK: "TASK" as ActivityType,
  CAMP: "CAMP" as ActivityType,
  CAMP_ELECTIVE: "CAMP_ELECTIVE" as ActivityType,
  RESOURCE_MAINTENANCE: "RESOURCE_MAINTENANCE" as ActivityType,
};

export type SeatBookingType = "NOT_AVAILABLE" | "MANDATORY" | "OPTIONAL";

export interface ActivityModel {
  id: number;
  name?: string;
  description?: string;
  activityType?: ActivityType;
  waitingListCapacity?: number;
  seatBooking?: SeatBookingType;
  group?: ActivityGroupModel;
  allowedDurations?: string[];
}

export interface ActivityTypeGroupModel {
  id: number;
  code?: ActivityType;
  name?: string;
  systemProperty?: string;
}

export const ActivityTypeGroupModelList = [
  { id: 1, code: ActivityType.CAMP, name: "Camp", systemProperty: "campsPage" },
  {
    id: 2,
    code: ActivityType.CLASS,
    name: "Class",
    systemProperty: "classBookingFlow",
  },
  {
    id: 3,
    code: ActivityType.COURSE,
    name: "Course",
    systemProperty: "coursesPage",
  },
  {
    id: 4,
    code: ActivityType.RESOURCE_BOOKING,
    name: "Resource booking",
    systemProperty: "resourceBookingPage",
  },
  {
    id: 5,
    code: ActivityType.STAFF_BOOKING,
    name: "Staff booking",
    systemProperty: "staffBookingPage",
  },
];

export interface ActivityGroupModel {
  id?: number;
  name?: string;
}

export interface BookingModel extends WithAgeRestrictionModel {
  id: number;
  date: string;
  centerId: number;
  activity: ActivityModel;
  name?: string;
  startTime?: string;
  endTime?: string;
  durationMinutes?: number;
  roomNames: string[];
  webRoomNames: Array<string>;
  instructorNames: string[];
  classCapacity?: number;
  bookedCount?: number;
  waitingListCount?: number;
  description?: string;
  bookingProgramId?: number;
}

export interface BookingDatesModel {
  date: string;
  count: number;
  loading: boolean; // UI only. is the counting still in progress?
  loaded: boolean; // UI only. is the counting finished?
}

export interface BookingSearchModel {
  dateFrom: string;
  dateTo: string;
  centers: number[];
  activityIds: number[];
  activityGroupIds: number[];
  instructor?: string;
}

export type BookingsToChangeEnum = "SINGLE" | "FOLLOWING" | "ALL";

export type ParticipationState =
  | "BOOKED"
  | "OVERBOOKED_WAITINGLIST"
  | "CANCELLED_BY_CENTER"
  | "CANCELLED_BY_USER"
  | "CANCELLED_BY_USER_TOO_LATE"
  | "OVERBOOKED_SHOWED_UP"
  | "BOOKED_BUT_LATE"
  | "PARTICIPATION"
  | "BOOKED_NO_SHOW_UP"
  | "SHOWED_UP_NO_PARTICIPATION"
  | "ATTEND"
  | "ATTEND_CANCELLED"
  | "ATTEND_IMPLICIT"
  | "INSTRUCTOR_ACTIVE"
  | "INSTRUCTOR_CANCELLED"
  | "BOOKED_IN_WAITING_LIST"
  | "TENTATIVE"
  | "CANCELLED_BY_BOOKING"
  | "CANCELLED_NO_PRIVILEGE";
export interface ParticipationModel {
  id: number;
  centerId: number;
  centerName: string;
  personId: number;
  personCenterId: number;
  booking: BookingModel;
  isUnFolded?: boolean; // Frontend only
  state?: ParticipationState;
  waitingListIndex?: number;
  participationListIndex?: number;
  seat?: SeatModel;
  updating?: boolean; // Frontend only
  personFirstName?: string;
  personLastName?: string;
}

export interface RecentActivityModel {
  activity?: string;
  bookingName?: string;
  personFirstName?: string;
  personLastName?: string;
  centerId?: number;
  centerName?: string;
  date?: string;
  time?: string;
  instructorName?: string[];
  participationId?: number;
  bookingId?: number;
  state?: string;
  activityType?: string; // frontend
}

export interface ActivitiesPageModel {
  recentActivities: RecentActivityModel[];
  isLastPage: boolean;
}

export interface SeatModel {
  name?: string;
  type?: "AVAILABLE" | "UNAVAILABLE" | "INSTRUCTOR" | "BOOKED" | "TENTATIVE";
  coordinateX?: number;
  coordinateY?: number;
}

//// EXERP API MISSING INTERFACES ////

export interface ProductModelApi {
  key: CompositeKeyModel;
  type?: ProductModelType;
  name?: string;
  price?: {
    customerPrice?: number;
    normalPrice?: number;
    sponsorPrice?: number;
  };
}

export type ProductModelType = "GOOD" | "SERVICE" | "CLIPCARD" | "SUBSCRIPTION";
export const ProductModelType = {
  Good: "GOOD" as ProductModelType,
  Service: "SERVICE" as ProductModelType,
  Clipcard: "CLIPCARD" as ProductModelType,
  Subscription: "SUBSCRIPTION" as ProductModelType,
};

export interface ResourceModel {
  key?: CompositeKeyModel;
  name?: string;
  comment?: string;
  externalId?: string;
  resourceGroupExternalIds?: string[];
  seats?: SeatModel[];
  instructorRow?: number;
  instructorPosition?: number;
}
